export const historyData = [{
    title:'CodeServer'
},{
    title:'智能家居'
},{
    title:'服务'
}]

export const hotData = [{
    title:"智能家居"
},{
    title:"APK"
},{
    title:"轻服务"
},{
    title:"SDK"
},{
    title:"DevOps"
},{
    title:"内容生成"
}]