import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-165140d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "tabs"
};
const _hoisted_2 = {
  key: 0,
  class: "dropContent"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = ["onClick"];
import { leftData } from "@/assets/data/header";
import { supportData } from "@/assets/data/header";
import { ref, computed, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { toPage } from "@/utils";
export default {
  __name: 'tabs',
  setup(__props) {
    const $px2rem = inject('$px2rem');
    const router = useRouter();
    const route = useRoute();
    let activeName = computed({
      get() {
        return route.name;
      }
    });
    let dropFlag = ref(false);
    const handleclick = index => {
      dropFlag.value = false;
      router.push(`/${leftData[index].name}`);
    };
    const handleMouseEnter = () => {
      dropFlag.value = true;
    };
    const handleMouseLeave = () => {
      dropFlag.value = false;
    };
    const handleToPage = item => {
      console.log(item);
      toPage(item);
    };
    return (_ctx, _cache) => {
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: _normalizeClass(["item", _unref(activeName) === _unref(leftData)[0].name ? 'actived' : '']),
        onClick: _cache[0] || (_cache[0] = $event => handleclick(0))
      }, [_createVNode(_component_el_dropdown, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(leftData)[0].title), 1)]),
        _: 1
      })], 2), _createElementVNode("div", {
        class: _normalizeClass(["item", _unref(activeName) === _unref(leftData)[1].name ? 'actived' : '']),
        onClick: _cache[1] || (_cache[1] = $event => handleclick(1))
      }, [_createVNode(_component_el_dropdown, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(leftData)[1].title), 1)]),
        _: 1
      })], 2), _createElementVNode("div", {
        class: _normalizeClass(["item", [_unref(activeName) === _unref(leftData)[2].name ? 'actived' : '', _unref(dropFlag) ? 'activeborder' : 'noBorder']]),
        style: _normalizeStyle({
          borderBottomWidth: _unref($px2rem)('6px')
        }),
        onClick: _cache[2] || (_cache[2] = $event => handleclick(2)),
        onMouseenter: handleMouseEnter,
        onMouseleave: handleMouseLeave
      }, [_createVNode(_component_el_dropdown, null, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(leftData)[2].title), 1)]),
        _: 1
      }), _unref(dropFlag) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(supportData), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "drop_item",
          key: index
        }, [_createElementVNode("div", _hoisted_3, _toDisplayString(item.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (innerItem, innerIndex) => {
          return _openBlock(), _createElementBlock("div", {
            class: "innerItem",
            key: innerIndex,
            onClick: _withModifiers($event => handleToPage(innerItem), ["stop"])
          }, _toDisplayString(innerItem.title), 9, _hoisted_4);
        }), 128))]);
      }), 128))])) : _createCommentVNode("", true)], 38)]);
    };
  }
};