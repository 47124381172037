export const leftData = [
  {
    title: "产品",
    name: "product", //和对应的路由路径要相同
  },
  {
    title: "应用场景",
    name: "scene",
  },
  {
    title: "开发支持",
    name: "support",
  },
];

export const rightData = [
  {
    title: "文档",
  },
  {
    title: "工作台",
    url:'https://code.ipanel.cn/user/view/workbench',
    isCheck:true
  },
];

export const supportData = [
  {
    title: "开发者社区",
    items: [
      { title: "专栏文章" },
      { title: "技术视频" },
      { title: "社区内容" },
    ],
  },
  {
    title: "开发者工具",
    items: [
      { title: "CodeSever" },
      { title: "DevOps" },
      { title: "SDK&API" },
      { title: "问答专区",url: "https://code.ipanel.cn/answer",isCheck:true },
    ],
  },
  {
    title: "帮助文档",
    items: [
      { title: "TV JS_app" },
      { title: "手机 JS_app" },
      { title: "IOT JS_app" },
      { title: "轻服务" },
      { title: "内容生成" },
    ],
  },
  {
    title: "快速上手",
    items: [
      { title: "入门指南" },
      { title: "新手指引" },
      { title: "实战演练" },
    ],
  },
];

export const loginData = {
  url:'https://code.ipanel.cn/user/view/login?redirect=Lw== '
}
