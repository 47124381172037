import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-36cd1eb7"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pageHeader"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  class: "right"
};
const _hoisted_4 = {
  style: {
    "position": "relative",
    "display": "inline-block"
  },
  class: "searchBox"
};
const _hoisted_5 = {
  key: 0,
  class: "searchTip"
};
const _hoisted_6 = {
  class: "historySearch"
};
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  class: "login"
};
const _hoisted_10 = ["onClick"];
import { Search } from "@element-plus/icons-vue";
import { computed, ref, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import { rightData, loginData } from "@/assets/data/header";
// import { userData } from "@/assets/data/user";
import { historyData, hotData } from "@/assets/data/searchTip";
import useSearchStore from "@/store/searchStore";
import { toPage } from "@/utils";
import useUserInfoStore from "@/store/userInfoStore";
import { userLogout } from "@/service";
import showAlert from "@/utils/showAlert";
import { emitter } from "@/utils/events";
import tipContent from "./tip-content.vue";
import tabs from '@/components/tabs.vue';

// console.log('page haeder')

export default {
  __name: 'page-header',
  setup(__props) {
    const $px2rem = inject('$px2rem');
    const baseUrl = process.env.BASE_URL;
    const store = useSearchStore();
    const userInfoStore = useUserInfoStore();
    const route = useRoute();
    const router = useRouter();
    let searchInputShowFlag = ref(false);
    let searchOnFocus = ref(false);
    let clearFocusTimeout = null;
    let clearFlag = false;
    const searchWord = computed({
      get() {
        return store.searchWord;
      },
      set(val) {
        store.changeSearchWord(val);
      }
    });
    let isLogin = computed(() => {
      // console.log('isLogin:',userInfoStore.userInfo.username)
      return userInfoStore.userInfo.username;
    });
    let userData = computed(() => {
      // console.log('userData',[{title:userInfoStore.userInfo.username},{title:'退出'}])
      return [{
        title: userInfoStore.userInfo.username
      }, {
        title: '退出'
      }];
    });
    let searchTipFlag = computed(() => {
      if (!searchInputShowFlag.value) {
        return false;
      } else {
        return searchOnFocus.value && searchWord.value === '';
      }
    });
    const handleCancel = () => {
      // alert(112)
      // console.log(112)
      clearFlag = true;
    };
    const handleLogoutDeal = async val => {
      if (val === '退出') {
        const params = {
          action: "logout"
        };
        const res = await userLogout(params);
        if (res?.reply === 'ok') {
          showAlert('退出成功!');
          userInfoStore.changeUserInfo({});
        } else {
          showAlert('退出失败!');
        }
      }
    };
    const handleSearchIconClick = () => {
      if (searchInputShowFlag.value) {
        if (!searchWord.value) {
          searchInputShowFlag.value = false;
        } else {
          //进行搜索并显示跳转到搜索页面
          if (route.path !== '/search') {
            router.push('/search');
          }
        }
      } else {
        searchInputShowFlag.value = true;
      }
    };
    const handleInputBlur = () => {
      // alert(2)
      // console.log(99)
      clearFocusTimeout = setTimeout(() => {
        searchOnFocus.value = false;
      }, 300);
      if (clearFlag) {
        clearTimeout(clearFocusTimeout);
        clearFlag = false;
      }
    };
    const handleCommand = () => {};
    const handleInputFocus = () => {
      searchOnFocus.value = true;
    };
    const handleToPage = async item => {
      // console.log(item)
      toPage(item);
    };
    emitter.on('hiddenHandle', () => {
      searchOnFocus.value = false;
    });
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
        src: `${_unref(baseUrl)}static/images/home/logo.png`,
        class: "logo",
        style: _normalizeStyle({
          top: _unref($px2rem)('-5px')
        })
      }, null, 8, ["src", "style"]), _createVNode(tabs)]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", {
        class: "search",
        style: _normalizeStyle({
          display: 'flex',
          alignItems: 'center',
          width: _unref($px2rem)('240px'),
          height: _unref($px2rem)('50px')
        })
      }, [_unref(searchInputShowFlag) ? (_openBlock(), _createBlock(_component_el_input, {
        key: 0,
        modelValue: searchWord.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => searchWord.value = $event),
        style: _normalizeStyle({
          width: _unref($px2rem)('240px')
        }),
        placeholder: "云服务器",
        onBlur: handleInputBlur,
        onFocus: handleInputFocus
      }, null, 8, ["modelValue", "style"])) : _createCommentVNode("", true), _createVNode(_component_el_icon, {
        size: _unref($px2rem)('20px'),
        color: _unref(searchInputShowFlag) ? '#0071BA' : '#666',
        class: "pointerCursor",
        style: _normalizeStyle({
          position: 'absolute',
          right: _unref($px2rem)('5px')
        }),
        onClick: handleSearchIconClick
      }, {
        default: _withCtx(() => [_createVNode(_unref(Search))]),
        _: 1
      }, 8, ["size", "color", "style"])], 4), _unref(searchTipFlag) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(tipContent, {
        rightIcon: `${_unref(baseUrl)}static/images/home/clear.png`,
        dataList: _unref(historyData),
        leftWord: "历史记录",
        rightWord: "清空",
        onHandleCancel: handleCancel
      }, null, 8, ["rightIcon", "dataList"])]), _createElementVNode("div", {
        class: "hotSearch",
        style: _normalizeStyle({
          marginTop: _unref($px2rem)('35px')
        })
      }, [_createVNode(tipContent, {
        leftIcon: `${_unref(baseUrl)}static/images/home/hot.png`,
        dataList: _unref(hotData),
        leftWord: "热门搜索",
        addStyle: "V1",
        onHandleCancel: handleCancel
      }, null, 8, ["leftIcon", "dataList"])], 4)])) : _createCommentVNode("", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(rightData), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "rightItem",
          onClick: $event => handleToPage(item)
        }, _toDisplayString(item.title), 9, _hoisted_7);
      }), 128)), _unref(isLogin) ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createVNode(_component_el_dropdown, {
        onCommand: handleCommand,
        "popper-class": "dropDown_style"
      }, {
        dropdown: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userData), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(['dropItem', index === 0 ? 'defaultCursor' : 'pointerCursor']),
            onClick: $event => handleLogoutDeal(item.title)
          }, _toDisplayString(item.title), 11, _hoisted_10);
        }), 128))]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createVNode(_component_el_image, {
          src: `${_unref(baseUrl)}static/images/home/avator.png`,
          class: "loginAvator"
        }, null, 8, ["src"])])]),
        _: 1
      })])) : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "noLogin",
        onClick: _cache[1] || (_cache[1] = $event => handleToPage(_unref(loginData)))
      }, "登录/注册"))])]);
    };
  }
};